<template>
  <div class="church-details template-2">
    <!-- / Page Header -->
    <form>
      <div class="page-body container mx-lg">
        <div class="details">
          <h2 class="">{{ translations.tcUploadFileCSV }}</h2>
          <div class="b-container">
            <div class="row"></div>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">CSV *</label>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-file
                        class="mb-1 mt-1"
                        style="text-transform: uppercase"
                        ref="file"
                        name="photo"
                        accept=".csv"
                        autofocus
                        :placeholder="translations.tcNoFileChosen"
                        :drop-placeholder="translations.tcDropFileHere"
                        :browse-text="translations.tcBrowse"
                      />
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="divButton">
          <b-button variant="primary" class="flex-0 w-100-sd mb-sm-0" size="sm" @click="upload()">
            {{ translations.tcUpload }}
          </b-button>
          &nbsp;
          <b-button variant="light" class="flex-0 w-100-sd mb-sm-0" size="sm" @click="cancel()">
            {{ translations.tcCancel }}
          </b-button>
        </div>
      </div>
    </form>
  </div>
  <!-- / Page Body -->
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-calendar-upload',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      file: '',
      categoryName: '',
      errorMessages: [],
    }
  },
  components: {
    appEvent: Event,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      uploadPrayerCalendarCSV: 'prayerCenter/uploadPrayerCalendarCSV',
    }),
    cancel() {
      this.$router.push({ name: 'prayer-manage-calendar' })
    },
    validate() {
      if (!this.file) {
        this.errorMessages = []
        this.errorMessages.push(this.translations.tcPleaseSelectAFile)
      }
      return this.errorMessages.length <= 0
    },
    async upload() {
      this.setLoadingStatus(true)
      this.file = this.$refs.file.files[0]
      if (!this.validate()) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: this.errorMessages.join(', '),
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return false
      }
      let formData = new FormData()
      formData.append('file', this.file)
      let params = {
        cprKey: this.selectedPrayerCalendarKey,
        formData: formData,
      }
      try {
        let response = await this.uploadPrayerCalendarCSV(params)
        if (response == true) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcSuccessfullyUploadedCSV,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      } catch(err) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcInvalidFileFormatUnableToImportCSV,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      this.setLoadingStatus(false)
      this.$router.push({
        name: 'prayer-manage-calendar',
      })
    },
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true)]).then(() => {
        this.setLoadingStatus(false)
      })
    },
  },
  async created() {
    if (!this.selectedPrayerCalendarKey) {
      return this.$router.push({ name: 'prayer-add-edit-calendar' })
    }
    await Promise.all([
      this.getViewTranslations(),
      this.pageLoad()
    ])
  },
  computed: {
    ...mapGetters({
      campKey: 'user/userCampKey',
      categories: 'prayerCenter/categories',
      internationalKey: 'user/internationalKey',
      prefCulture: 'user/userPreferredCulture',
      selectedPrayerCalendarKey: 'prayerCenter/selectedPrayerCalendarKey',
      stateKey: 'user/userStateKey',
      types: 'prayerCenter/types',
      userIndividualKey: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
    }),
  },
}
</script>

<style lang="scss" scoped>

div.container {
  padding: 20px 20px;
  padding-bottom: 50px;
}
</style>
<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

div.b-container {
  padding: 20px 20px;
}

.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}

div.namediv {
  h5 {
    font-weight: 600;
    margin-top: 10px;
  }
  width: 80px;
}

div.numberdays {
  h6 {
    font-size: 14px;
    margin-top: 15px;
    font-weight: 600;
  }
}

div.row {
  margin-top: 10px;
}

textarea {
  width: 100%;
  height: 250px;
}

div.divButton {
  margin-left: 10px;
}

div.divDetail {
  h5 {
    font-weight: 600;
    margin-top: 5px;
  }
  margin-top: 50px;
}

.details {
  margin-bottom: 46px;
  padding: 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);

  h2 {
    color: #000;
    text-transform: uppercase;
  }

  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
      > * {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#example-datepicker__outer_ .btn {
  color: #212529;
}

input {
  line-height: 40px;
}

input[type='text'] {
  width: 700px;
}

input[type='number'] {
  width: 50px;
}
.primary {
  margin-bottom: 35px;
  > .col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
.upload-image {
  margin-top: 30px !important;
}
</style>
